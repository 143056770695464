import React from "react";
import Image from "../../../image/image";
import { FormattedMessage } from "react-intl";
import { Link } from "react-scroll";
import classNames from "classnames";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Animation } from "../../../../hoc/with-animation";
import Slider from "react-slick";

const InnerMedia = ({ media }) => (
  <>
    {media.entity.entityBundle === "bild" && (
      <Image data={media.entity.fieldMediaImage} fullPage={true} />
    )}
    {media.entity.entityBundle === "video" && (
      <video
        src={media.entity.fieldMediaVideoFile.entity.url}
        autoPlay={true}
        muted={true}
        loop={true}
      ></video>
    )}
  </>
);

const ParagraphIntro = (props) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-intro": true,
    fullpage: props.content.fieldDisplayMode === "fullpage",
  });

  const images =
    props.content.fieldBackgroundImage?.filter((image) => image.entity) || [];

  const sliderSettings = {
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };

  if (props.content.fieldDisplayMode === "fullpage") {
    return (
      <section className={sectionClassNames}>
        <Animation>
          <div className="container upper">
            {images.length === 1 && <InnerMedia media={images[0]} />}
            {images.length > 1 && (
              <Slider {...sliderSettings}>
                {images.map((image, i) => (
                  <InnerMedia media={image} key={i} />
                ))}
              </Slider>
            )}
            {props.content.fieldTicketButton && (
              <a
                className="button-tickets"
                href={props.content.fieldTicketButton.url.path}
                target="_blank"
                rel="noreferrer"
              >
                <span className="ticket-link-main">
                  <FormattedMessage id="button.tickets" />
                </span>
                <span className="ticket-link-sub">
                  <FormattedMessage id="button.for_events" />
                </span>
              </a>
            )}
            <Link className="button-more mobile" to="next" duration={500}>
              <span className="icon-wrapper">
                <span className="icon icon-arrow-left"></span>
              </span>
              <span className="label-wrapper">
                <FormattedMessage id="learn_more" />
              </span>
            </Link>

            <div className="row">
              <div className="col-8 headline-wrapper desktop">
                <Link className="button-more desktop" to="next" duration={500}>
                  <span className="icon-wrapper">
                    <span className="icon icon-arrow-left"></span>
                  </span>
                  <span className="label-wrapper">
                    <FormattedMessage id="learn_more" />
                  </span>
                </Link>
                <div className="row">
                  <div className="col-14 offset-2 text-wrapper">
                    <p className="big">
                      {props.content.fieldTitle && props.content.fieldTitle}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Animation>
        <span id="next" />
        <Animation>
          <div className="container lower">
            <div className="row">
              <div className="col-12 col-md-13 offset-1 text">
                <div
                  className="description desktop"
                  dangerouslySetInnerHTML={
                    props.content.fieldText.processed && {
                      __html: props.content.fieldText.processed,
                    }
                  }
                />
                <div className="mobile title">
                  <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <p className="big">
                            {props.content.fieldTitle &&
                              props.content.fieldTitle}
                          </p>
                          <AccordionItemPanel>
                            <div
                              className="description"
                              dangerouslySetInnerHTML={
                                props.content.fieldText.processed && {
                                  __html: props.content.fieldText.processed,
                                }
                              }
                            />
                          </AccordionItemPanel>
                          <a className="mehr">mehr anzeigen</a>
                          <a className="weniger">weniger anzeigen</a>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </Animation>
      </section>
    );
  }

  return (
    <section className={sectionClassNames}>
      {images.length === 1 && <InnerMedia media={images[0]} />}
      {images.length > 1 && (
        <Slider {...sliderSettings}>
          {images.map((image, i) => (
            <InnerMedia media={image} key={i} />
          ))}
        </Slider>
      )}
      <div className="container">
        <div className="row">
          <div className="col-12 offset-1 headline-wrapper">
            <h1 className="headline-with-bg">
              {props.content.fieldTitle && props.content.fieldTitle}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 offset-1">
            <div
              className="description big"
              dangerouslySetInnerHTML={
                props.content.fieldText.processed && {
                  __html: props.content.fieldText.processed,
                }
              }
            />
          </div>
        </div>
      </div>
      <Link className="button-more" to="next" duration={500}>
        <span className="icon-wrapper">
          <span className="icon icon-arrow-left"></span>
        </span>
        <span className="label-wrapper">
          <FormattedMessage id="learn_more" />
        </span>
      </Link>
      <span id="next" />
    </section>
  );
};

export default ParagraphIntro;
