import React, { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import runScripts from "./run-scripts";
import { cookieBannerConfigQuery } from "./cookie-banner-config-query.graphql";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

const CookieBanner = () => {
  const [selection, setSelection] = useLocalStorage("consent", false);
  const [consentVersion, setConsentVersion] = useLocalStorage(
    "consentVersion",
    false
  );
  const [isClient, setIsClient] = useState(false);
  const currentLanguage = useSelector((state) => state.i18n.currentLanguage);

  const { data, loading } = useQuery(cookieBannerConfigQuery);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const config = data?.configPagesQuery?.entities?.[0];

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (e.nativeEvent.submitter.name === "select-all") {
      setSelection((prev) => ({
        ...prev,
        necessary: true,
        statistics: true,
      }));
      setConsentVersion(config?.fieldVersion?.value);
    }
    if (e.nativeEvent.submitter.name === "confirm-selection") {
      setSelection((prev) => ({
        ...prev,
        necessary: true,
        statistics: formData.get("statistics") === "on",
      }));
      setConsentVersion(config?.fieldVersion?.value);
    }

    runScripts(true);
  };

  /**
   * Don't render cookie banner if:
   * - User has already made a selection
   * - Component is being rendered on the server
   */
  if (!isClient) {
    return null;
  }
  if ((selection || loading) && consentVersion >= config?.fieldVersion?.value) {
    return null;
  }

  const body = currentLanguage === "en" ? config?.fieldBodyEn?.processed : config?.fieldBodyDe?.processed;

  return (
    <>
      <section className="cookie-banner-wrapper">
        <div className="cookie-banner">
          <div className="cookie-banner-inner">
            {body ? (
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: body,
                }}
              />
            ) : (
              <div className="text">Loading...</div>
            )}
            <form onSubmit={submitHandler} className="cookie-banner-form text">
              <legend>
                <FormattedMessage id="cookie.settings" />
              </legend>
              <label className="d-flex">
                <input
                  type="checkbox"
                  name="necessary"
                  id="necessary"
                  defaultChecked={true}
                  disabled
                />
                <span>
                  <FormattedMessage id="cookie.necessary" />
                </span>
              </label>
              <label className="d-flex">
                <input
                  type="checkbox"
                  name="statistics"
                  id="statistics"
                  defaultChecked={selection.statistics}
                />
                <span>
                  <FormattedMessage id="cookie.statistics" />
                </span>
              </label>
              <div className="action-wrapper d-flex justify-content-between flex-column flex-lg-row align-items-center">
                <button
                  type="submit"
                  name="confirm-selection"
                  className="btn btn-primary"
                >
                  <FormattedMessage id="cookie.accept_selection" />
                </button>
                <button
                  type="submit"
                  name="select-all"
                  className="btn btn-primary"
                >
                  <FormattedMessage id="cookie.accept_all" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

CookieBanner.propTypes = {};

export default CookieBanner;
