import { cookieBannerConfigQuery } from "./cookie-banner-config-query.graphql";

const runScripts = async (skipValidation, client = false) => {
  const consent = JSON.parse(localStorage.getItem("consent"));
  const consentVersion = JSON.parse(localStorage.getItem("consentVersion"));

  if (!skipValidation) {
    const { data: config } = await client.query({
      query: cookieBannerConfigQuery,
    });
    const version =
      config?.configPagesQuery?.entities?.[0]?.fieldVersion?.value || false;

    if (!version || version > consentVersion) {
      return;
    }
  }

  if (consent?.statistics) {
    runGoogleAnalytics("G-F3T487JK3W");
    runMetaPixel("1151710423229159");
  }
};

const runGoogleAnalytics = (id) => {
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-F3T487JK3W";
  document.head.append(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", id);
};

const runMetaPixel = (id) => {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  );
  fbq("init", id);
  fbq("track", "PageView");
};

export default runScripts;
