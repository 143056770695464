import React, { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";

// Router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./i18n/translations/de.json";
import messages_en from "./i18n/translations/en.json";

// Page Components
import Landingpage from "./landingpage/landingpage";
import Person from "./person/person";
import footerConfigQuery from "footer-config-query.graphql";
import headerConfigQuery from "header-config-query.graphql";
import PageFooter from "./footer/page-footer";
import menuQuery from "./general-components/menu-query.graphql";
import Header from "./header/header";
import ErrorBoundary from "./error-boundary";
import NodeRevision from "./backend/NodeRevision";
import Article from "./article/article";
import Searchpage from "./header/search/searchpage";
import CookieBanner from "./general-components/cookies/cookie-banner";
import RunScripts from "./general-components/cookies/run-scripts";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({ reduxStore });

const messages = {
  de: messages_de,
  en: messages_en,
};

/**
 * @todo 404.
 * @todo Fetch Page title and push to store. Use for <title> tag.
 */
class App extends Component {
  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  componentDidMount() {
    document.body.className += `${this.generateMainClasses(
      this.props.location.pathname
    )}`;
  }

  componentDidUpdate(prevProps) {
    // Scroll to top on route change
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.hash
    ) {
      window.scrollTo(0, 0);
      document.body.className = `${this.generateMainClasses(
        this.props.location.pathname
      )}`;
    }
  }

  render() {
    return (
      <IntlProvider
        locale={this.props.reduxStore.i18n.currentLanguage}
        defaultLocale="de"
        messages={messages[this.props.reduxStore.i18n.currentLanguage]}
      >
        <div className="main-content">
          <ErrorBoundary>
            <Header
              socialMediaConfig={this.props.footerConfig.configPagesById}
              headerConfig={this.props.headerConfig.configPagesById}
            />
          </ErrorBoundary>

          <main className="main-page-content">
            <ErrorBoundary>
              <Switch
                location={this.props.location}
                key={this.props.location.pathname}
              >
                <Route
                  exact
                  path="/:language?/projekt/:alias"
                  component={Landingpage}
                />
                <Route exact path="/:language?/news" component={Landingpage} />
                <Route
                  exact
                  path="/:language?/news/:alias"
                  component={Article}
                />
                <Route
                  exact
                  path="/:language?/veranstaltung/:alias"
                  component={Article}
                />
                <Route
                  exact
                  path="/:language?/pressemeldung/:alias"
                  component={Article}
                />
                <Route
                  exact
                  path="/:language?/person/:alias"
                  component={Person}
                />
                <Route
                  exact
                  path="/:language?/stellenangebot/:alias"
                  component={Article}
                />
                <Route
                  exact
                  path="/:language?/bildungsangebot/:alias"
                  component={Article}
                />
                <Route
                  exact
                  path="/:language?/educational-offer/:alias"
                  component={Article}
                />
                <Route path="/:language?/suche" component={Searchpage} />
                <Route
                  exact
                  path="/node/:nodeId/revisions/:revisionId/view"
                  component={NodeRevision}
                />
                <Route
                  exact
                  path="/:language?/:alias+"
                  component={Landingpage}
                />
                <Route exact path="/" component={Landingpage} />
              </Switch>
            </ErrorBoundary>

            <ErrorBoundary>
              <CookieBanner />
            </ErrorBoundary>
          </main>

          <footer id="pageFooter">
            <ErrorBoundary>
              <PageFooter
                footerConfig={this.props.footerConfig.configPagesById}
                menu={this.props.menuQuery.menuByName}
              />
            </ErrorBoundary>
          </footer>
        </div>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  footerConfig: PropTypes.object.isRequired,
  menuQuery: PropTypes.object.isRequired,
  reduxStore: PropTypes.object,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  compose(
    graphql(footerConfigQuery, {
      name: "footerConfig",
    }),
    graphql(headerConfigQuery, {
      name: "headerConfig",
    }),
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "footer",
          language: props.reduxStore.i18n.currentLanguage
            ? props.reduxStore.i18n.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "menuQuery",
    })
  )(withRouter(App))
);
