import React from "react";

const ParagraphCookieConsent = () => {
  const resetConsent = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <section className="paragraph paragraph-cookie-consent">
      <div className="container">
        <div className="row">
          <div className="col-wrapper col-12 offset-1">
            <button className="btn btn-primary" onClick={resetConsent}>
              Einwilligung widerrufen und Cookie-Banner erneut anzeigen
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ParagraphCookieConsent;
