import React, { useEffect, useRef, useState } from "react";

import { withRouter } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { connect } from "react-redux";
import menuQuery from "../general-components/menu-query.graphql";
import Navigation from "./navigation";
import Search from "./search/search";
import LanguageSwitch from "./accessibility-menu/language-switch";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import SocialMedia from "./social-media/social-media";
import VisitorMenu from "./accessibility-menu/visitor-menu";

const mapStateToProps = (reduxStore) => ({
  i18n: reduxStore.i18n,
});

const HeaderMain = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [visitorOpen, setVisitorOpen] = useState(false);
  const visitorRef = useRef();
  const searchBoxRef = useRef();

  /**
   * Full Screen Menu
   */
  const toggleFullScreenMenu = () => {
    setMenuOpen((prev) => !prev);
    setVisitorOpen(false);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("fullscreen-menu-open");
    } else {
      document.body.classList.remove("fullscreen-menu-open");
    }
  }, [menuOpen]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      toggleFullScreenMenu();
    }
  };

  /**
   * Search Behavior
   */
  useEffect(() => {
    const outsideSearchClickHandler = (e) => {
      if (!searchBoxRef.current.contains(e.target)) {
        setSearchOpen(false);
      }
    };

    if (searchOpen) {
      setTimeout(() => {
        document.addEventListener("click", outsideSearchClickHandler);
      }, 50);
    } else {
      document.removeEventListener("click", outsideSearchClickHandler);
    }

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("click", outsideSearchClickHandler);
    };
  }, [searchOpen]);

  const openSearchBar = () => {
    setSearchOpen(true);
    setMenuOpen(false);
    setVisitorOpen(false);
  };

  const closeSearchBar = () => {
    setSearchOpen(false);
  };

  return (
    <>
      <div className="header-main">
        <div className="logo-wrapper">
          <img src="/logo-sfvv-small.svg" />
        </div>
        <div
          className="visitor-back-button"
          hidden={!visitorOpen}
          onClick={() => setVisitorOpen(false)}
        >
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1_10)">
              <path d="M14 28H42" stroke="black" strokeWidth="2px" />
              <path d="M23.5 19L14 28L23 37" stroke="black" strokeWidth="2px" />
            </g>
            <defs>
              <clipPath id="clip0_1_10">
                <rect width="56" height="56" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <nav className="col main-menu-desktop">
          {!props.mainMenuQuery.loading && props.mainMenuQuery.menuByName && (
            <Navigation
              menu={props.mainMenuQuery.menuByName}
              location={props.location}
              desktop
            />
          )}
          <Link className="btn newsletter-btn" to="/de/newsletter">
            <FormattedMessage id="newsletter.register" />
          </Link>
        </nav>
        <div
          role="button"
          className="icon icon-search"
          onClick={openSearchBar}
        />
        <div
          id="toggle-fullscreen-menu"
          tabIndex={0}
          className={menuOpen ? "active" : ""}
          aria-label="toggle-full-screen-menu"
          onClick={toggleFullScreenMenu}
          onKeyDown={handleKeyDown}
        >
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
              d="M2 8H30"
              stroke="black"
              strokeWidth="2px"
              className="line-1"
            ></path>
            <path
              d="M2 16H30"
              stroke="black"
              strokeWidth="2px"
              className="line-2"
            ></path>
            <path
              d="M2 16H30"
              stroke="black"
              strokeWidth="2px"
              className="line-3"
            ></path>
            <path
              d="M2 24H30"
              stroke="black"
              strokeWidth="2px"
              className="line-4"
            ></path>
          </svg>
        </div>
      </div>

      <div className="searchbox" ref={searchBoxRef}>
        {searchOpen && <Search onRequest={closeSearchBar} focus={true} />}
      </div>

      <div id="fullscreen-menu" className={menuOpen ? "active" : ""}>
        <div className="nav-wrap">
          <div className="container">
            <div className="row">
              <div className="col-16 d-flex flex-column justify-content-between">
                <nav className="main-menu-mobile">
                  {!props.mainMenuQuery.loading &&
                    props.mainMenuQuery.menuByName && (
                      <div hidden={visitorOpen}>
                        <Navigation
                          menu={props.mainMenuQuery.menuByName}
                          location={props.location}
                          mobile
                          onCloseMobileMenu={toggleFullScreenMenu}
                        />
                      </div>
                    )}
                  <div hidden={visitorOpen} className="language-switch-wrapper">
                    <LanguageSwitch
                      destination={visitorRef.current}
                      open={visitorOpen}
                      onClick={() => setVisitorOpen(true)}
                    />
                  </div>
                  {visitorOpen && (
                    <VisitorMenu
                      i18n={props.i18n}
                      ignoreOutsideClick={true}
                      onClick={toggleFullScreenMenu}
                    />
                  )}
                </nav>
                <div className="main-menu-mobile-footer">
                  <SocialMedia socialMediaConfig={props.socialMediaConfig} />
                  {props.newsletterUrl && (
                    <Link
                      className="btn newsletter-btn"
                      to={props.newsletterUrl}
                    >
                      <FormattedMessage id="newsletter.register" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(
  compose(
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "main",
          language: props.i18n.currentLanguage
            ? props.i18n.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "mainMenuQuery",
    })
  )(withRouter(HeaderMain))
);
