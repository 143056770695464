import React from "react";

const SocialMedia = ({ socialMediaConfig }) => {
  const socialMedia = [
    {
      name: "facebook",
      url: socialMediaConfig?.fieldFacebook?.url?.path,
      icon: <img src="/facebook.svg" width="24" height="24" />,
    },
    {
      name: "instagram",
      url: socialMediaConfig?.fieldInstagram?.url?.path,
      icon: <img src="/instagram.svg" width="24" height="24" />,
    },
    {
      name: "x",
      url: socialMediaConfig?.fieldTwitter?.url?.path,
      icon: <img src="/twitter.svg" width="24" height="24" />,
    },
    {
      name: "youtube",
      url: socialMediaConfig?.fieldYoutube?.url?.path,
      icon: <img src="/youtube.svg" width="24" height="24" />,
    },
    {
      name: "linkedin",
      url: socialMediaConfig?.fieldLinkedin?.url?.path,
      icon: <img src="/linkedin.svg" width="24" height="24" />,
    },
  ].filter((item) => item.url);

  return (
    <ul className="social-media">
      {socialMedia.map((item) => (
        <li key={item.name}>
          <a href={item.url} target="_blank" rel="noreferrer">
            {item.icon}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SocialMedia;
